import React, { useState, useRef, useEffect } from "react";
import { motion} from "framer-motion"
import TextLoop from "react-text-loop";


import useMousePosition from "./hooks/useMousePosition"

import img1 from "./assets/pexels-1.jpg";
import img2 from "./assets/pexels-2.jpg";
import img3 from "./assets/pexels-3.jpg";
import img4 from "./assets/pexels-4.jpg";

import "reset-css";
import "./App.scss";

/**
   *  Utility functions
   */
function utils() {
  
  const calcMaxHeight = function (items) {
    let maxHeight = 0;

    items.forEach(item => {
      const h = item.clientHeight;
      maxHeight = h > maxHeight ? h : maxHeight;
    });
    return maxHeight;
  }

  
  function removeClasses(nodeList, cssClasses) {
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].classList.remove(...cssClasses);
    }
  }

  function addClasses(nodeList, cssClasses) {
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].classList.add(...cssClasses);
    }
  }

  const requestInterval = function (fn, delay) {
    const requestAnimFrame = (function () {
      return window.requestAnimationFrame || function (callback, element) {
        window.setTimeout(callback, 1000 / 60);
      };
    })();

    let start = new Date().getTime(),
      handle = {};

    function loop() {
      const current = new Date().getTime(),
        delta = current - start;

      if (delta >= delay) {
        fn.call();
        start = new Date().getTime();
      }

      handle.value = requestAnimFrame(loop);
    }

    handle.value = requestAnimFrame(loop);
    return handle;
  };

  const clearRequestInterval = function (handle) {
    window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
      clearInterval(handle);
  };

  return {
    calcMaxHeight,
    removeClasses,
    addClasses,
    requestInterval,
    clearRequestInterval
  }
}

/***  Main Slider function  */
function heroSlider() {
  const slider = {
    hero: document.querySelector('#hero-slider'),
    main: document.querySelector('#slides-main'),
    aux: document.querySelector('#slides-aux'),
    sliderNav: document.querySelector('#slider-nav'),
    current: document.querySelector('#slider-nav .current'),
    circle: document.querySelector('#circle'),
    info: document.querySelector('#info'),
    handle: null,
    idle: true,
    activeIndex: -1,
    interval: 12000
  };

  const setHeight = function (holder, items) {
    const h = utils().calcMaxHeight(items);
    holder.style.height = `${h}px`;
  }

  const leadingZero = function () {
    return arguments[1] < 10 ? '0' + arguments[1] : arguments[1];
  }

  const setCurrent = function () {
    if (slider.activeIndex == 0 || slider.activeIndex == 5) {
      slider.sliderNav.style.opacity = "0";
    } else {
      slider.sliderNav.style.opacity = "1";
      let element = document.getElementsByClassName("sep")[0];
      element.style.setProperty("--dynamic-width", `${(slider.activeIndex) * 25}%`);
      slider.current.innerText = leadingZero`${slider.activeIndex}`;
    }
  }

  const setInfo = function () {
    if (slider.activeIndex == 0) {
      slider.info.style.opacity = "1";
    } else {
      slider.info.style.opacity = "0";
    }
  }

  const setCircle = function () {
    if (slider.activeIndex == 0 || slider.activeIndex == 5) {
      slider.circle.style.opacity = "0";
    } else {
      slider.circle.style.opacity = "1";
      let circleInner = document.querySelector('.circle-inner');
      const colors = ["#FC4445", "salmon", "#97CAEF", '#EE4C7C', "#D79922"];
      circleInner.style.backgroundColor = colors[slider.activeIndex];
      slider.circle.style.transform = `rotate(${slider.activeIndex * 120}deg )`;
    }
  }



  const changeSlide = function (direction) {
    slider.idle = false;
    slider.hero.classList.remove('prev', 'next');
    if (direction == 'next') {
      slider.activeIndex = (slider.activeIndex + 1) % slider.total;
      slider.hero.classList.add('next');
    } else {
      slider.activeIndex = (slider.activeIndex - 1 + slider.total) % slider.total;
      slider.hero.classList.add('prev');
    }

    //reset classes
    utils().removeClasses(slider.items, ['prev', 'active']);

    //set prev  
    const prevItems = [...slider.items]
      .filter(item => {
        let prevIndex;
        if (slider.hero.classList.contains('prev')) {
          prevIndex = slider.activeIndex == slider.total - 1 ? 0 : slider.activeIndex + 1;
        } else {
          prevIndex = slider.activeIndex == 0 ? slider.total - 1 : slider.activeIndex - 1;
        }

        return item.dataset.index == prevIndex;
      });

    //set active
    const activeItems = [...slider.items]
      .filter(item => {
        return item.dataset.index == slider.activeIndex;
      });

    utils().addClasses(prevItems, ['prev']);
    utils().addClasses(activeItems, ['active']);
    setCurrent();
    setCircle();
    setInfo();

    const activeImageItem = slider.main.querySelector('.active');
    console.log(activeImageItem, "active item")
    activeImageItem.addEventListener('transitionend', waitForIdle, {
      once: true
    });
  }

  const stopAutoplay = function () {
    slider.autoplay = false;
    utils().clearRequestInterval(slider.handle);
  }

  const waitForIdle = function () {
    !slider.autoplay && autoplay(false); //restart
    slider.idle = true;
  }

  const wheelControl = function () {
    slider.hero.addEventListener('wheel', e => {
      if (slider.idle) {
        const direction = e.deltaY > 0 ? 'next' : 'prev';
        stopAutoplay();
        console.log(e.deltaY);
        changeSlide(direction);
      }
    });
  }

  const autoplay = function (initial) {
    slider.autoplay = true;
    slider.items = slider.hero.querySelectorAll('[data-index]');
    slider.total = slider.items.length / 2;

    const loop = () => changeSlide('next');

    initial && requestAnimationFrame(loop);
    slider.handle = utils().requestInterval(loop, slider.interval);
  }

  const loaded = function () {
    slider.hero.classList.add('loaded');
  }

  const touchControl = function () {
    const touchStart = function (e) {
      slider.ts = parseInt(e.changedTouches[0].clientX);
      window.scrollTop = 0;
    }

    const touchMove = function (e) {
      slider.tm = parseInt(e.changedTouches[0].clientX);
      const delta = slider.tm - slider.ts;
      window.scrollTop = 0;

      if (slider.idle) {
        const direction = delta < 0 ? 'next' : 'prev';
        stopAutoplay();
        changeSlide(direction);
      }
    }

    slider.hero.addEventListener('touchstart', touchStart);
    slider.hero.addEventListener('touchmove', touchMove);
  }

  const start = function () {
    autoplay(true);
    wheelControl();
    window.innerWidth <= 1024 && touchControl();
    slider.aux.addEventListener('transitionend', loaded, {
      once: true
    });
  }

  const loadingAnimation = function () {
    slider.hero.classList.add('ready');
    slider.current.addEventListener('transitionend', start, {
      once: true
    });
  }

  const init = function () {
    setHeight(slider.aux, slider.aux.querySelectorAll('.slide-title'));
    loadingAnimation();
  }

  const resize = function () {
    setHeight(slider.aux, slider.aux.querySelectorAll('.slide-title'));
  }

  return {
    init,
    resize
  }
}

function App() {

  const [cursorHovered, setCursorHovered] = useState(false)
  const [textHover, setTextHover] = useState(false)


  const list = useRef();
  const logo = useRef();
  const { x, y } = useMousePosition()


  const [opacityState, setOpacityState] = useState(false)
  const [hoverState, setHoverState] = useState(false)
  const [dimensions, setDimensions] = useState({
    top: 0,
    left: 0,
  })

  useEffect(() => {
    setDimensions({
      top: logo.current.getBoundingClientRect().top,
      left: logo.current.getBoundingClientRect().left,
    })
  }, [hoverState])

  useEffect(() => {
    window.addEventListener('load', heroSlider().init);
    window.addEventListener("resize", heroSlider().resize);
  }, []);

const handleHoverStart = () => {
  setOpacityState(true);
  setTimeout(() => {
    setHoverState(true)
  }, 800);
}

const handleHoverEnd = () => {
  setTextHover(false);
  setOpacityState(false);
  setHoverState(false);
}

  return (
    <>
      <motion.div
        animate={{
          x: x - 16,
          y: y - 16,
          scale: cursorHovered ? 1.4 : opacityState ? 0.2 : 2.5,
          opacity: cursorHovered ? 0.9 : 1,
        }}
        transition={{
          ease: "linear",
          duration: 0.3,
        }}
        className="cursor"
      >
        <p className="cursor-text">{textHover ? "" : "view"}</p>
      </motion.div>
      <div id="hero-slider">
        <div
          ref={logo}
          onMouseEnter={() => {
            setCursorHovered(true);
            setTextHover(true);
          }}
            onMouseLeave={() => { setCursorHovered(false); setTextHover(false) }} 
          id="logo" className="mask">
          <motion.div  animate={{
              opacity: opacityState ? 0 : 1,
            }}
            className="logo-text"
          >Euro.M</motion.div>
        </div>

        <motion.div 
          onHoverStart={handleHoverStart}
          onHoverEnd={handleHoverEnd} 
          onMouseEnter={handleHoverStart}
          onMouseLeave={handleHoverEnd} 
          className="floating-box">
        </motion.div>
        <motion.div
            initial={{ opacity: 0 }}
            animate={{
              rotate: 90,
              opacity: hoverState ? 1 : 0,
              x: x-2,
              y: y-2  ,
            }}
            transition={{
              type: "spring",
            }}
            className="floating-text"
          >
            Euro.M
        </motion.div>


        <div onMouseEnter={() => {
            setCursorHovered(true)
            setTextHover(false)
          }}
            onMouseLeave={() => { 
              setCursorHovered(false) 
              setTextHover(true) }} id="slideshow">
          <div 
              id="slides-main" className="slides">


            <div className="slide" data-index="0">
              <div className="intro" style={{ background: "transparent" }}>
          
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                  transition={{ delay: 3.1, duration: 0.5 }} className="intro-subtitle">Euro.M is
                  
                   <TextLoop delay={4000}  interval={1800}  fade={true}>
                    <span className="textloop">a global</span>
                    <span className="textloop">an innovative</span>
                    <span className="textloop">a great</span>
                  </TextLoop>
                  agency.
                </motion.div>

                <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                  transition={{ delay: 2.9, duration: 0.5 }} className="intro-title">We make   
                  <TextLoop delay={4000} interval={2800} fade={true} >
                  <span className="textloop">digital things.</span>
                  <span className="textloop">print things.</span>
                  <span className="textloop">great things.</span>
                </TextLoop> 
                </motion.h2></div>

            </div>
            <div className="slide" data-index="1">
              <div className="abs-mask">
                <img src={img1} className="img-1" alt="flag" />
                <div className="slide-image" style={{ background: "blue" }}>
                </div>
              </div>
            </div>
            <div className="slide" data-index="2">
              <div className="abs-mask">
                <img src={img2} className="img-1" alt="flag" />
                <div className="slide-image" style={{ background: "lightgreen" }}></div>
              </div>
            </div>
            <div className="slide" data-index="3">
              <div className="abs-mask">
                <img src={img3} className="img-1" alt="flag" />
                <div className="slide-image" style={{ background: "red" }}></div>
              </div>
            </div>
            <div className="slide" data-index="4">
              <div className="abs-mask">
                <img src={img4} className="img-1" alt="flag" />
                <div className="slide-image" style={{ background: "yellow" }}></div>
              </div>
            </div>
            <div className="slide" data-index="5">
              <div className="contact" style={{ background: "black" }}>
              
              </div>
            </div>
          </div>
          <div onMouseEnter={() => {
            setCursorHovered(false)
            setTextHover(true)
          }}
            onMouseLeave={() => { 
              setCursorHovered(true) 
              setTextHover(false) }} id="slides-aux" className="slides mask">
            <div className="slide-title slide" data-index="0"><a href="#"></a></div>

            <div className="slide-title slide" data-index="1"><p className="slide-text">Design & Branding</p><a href="#">Hitachi cooling.</a></div>



            <div className="slide-title slide" data-index="2"><p className="slide-text" >Design & Branding</p><a href="#">Iota Foundation.</a></div>

            <div className="slide-title slide" data-index="3"><p className="slide-text" >Design & Branding</p><a href="#">Merge VR Website.</a></div>

            <div className="slide-title slide" data-index="4"><p className="slide-text" >Design & Branding</p><a href="#">Designual Look Book.</a></div>

            <div className="slide-title slide" data-index="5"><a href="#">Contact Us.</a></div>
          </div>
          <div id="circle"><div className="circle-inner">
          </div></div>
        </div>
        <div id="info">
          <div  className="slider-title-wrapper">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
            <span className="line line4"></span>
            <span   
             className="line line5">Scroll down</span>
          </div>

        </div>
        <nav id="slider-nav">
          <span className="current">01</span>
          <span className="sep"></span>
          <span className="total">04</span>
        </nav>
      </div>
    </>
  );
}

export default App;
